import Vue from 'vue';
import Breakpoints from '../vue/breakpoints';
import LazyLoad from "vanilla-lazyload";
import ScrollOut from "scroll-out";
import GLightbox from 'glightbox'

import { MatchHeight } from 'js-match-height';

// Intiate Vuejs instance
var app = new Vue({
    el: '#app',
    delimiters: ["${","}"],
    components: {
        Breakpoints,
    }
})

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    var lightbox = GLightbox();

    const matchHeight = new MatchHeight('.section-personas h3');
    const matchHeight2 = new MatchHeight('.completed-homes h3');

    fadeInPage();

    // Page transition functionality
    function fadeInPage() {
        if (!window.AnimationEvent) { return; }
        var fader = document.getElementById('fader');
        fader.classList.add('fade-out');
    }

    if (!window.AnimationEvent) { return; }

    var anchors = document.querySelectorAll('.fade-btn');
    
    for (var idx=0; idx<anchors.length; idx+=1) {

        if (anchors[idx].hostname !== window.location.hostname) {
            continue;
        }

        anchors[idx].addEventListener('click', function(event) {
                var fader = document.getElementById('fader'),
                    anchor = event.currentTarget;
                
                var listener = function() {
                    window.location = anchor.href;
                    fader.removeEventListener('animationend', listener);
                };
                fader.addEventListener('animationend', listener);
                
                event.preventDefault();
                fader.classList.add('fade-in');
        });  
    }    

    window.addEventListener('pageshow', function (event) {
        if (!event.persisted) {
            return;
        }
        var fader = document.getElementById('fader');
        fader.classList.remove('fade-in');
    });
    
    // LAZYLOAD
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    // ACCORDIONS
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    } 

    function toggleAccordion(e) {
    
        if(e.target.parentElement.classList.contains('accordion-item--active')) {
            e.target.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === e.target) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    // TOOGLE MOBILE MENU //
    const navTrigger = document.querySelector('.js-open-nav');
    const mobNav = document.querySelector('.mob-nav');
    const projectWrapper = document.getElementById('app');

    if(navTrigger){ 
        bindEvent(navTrigger, 'click', function() {
            navTrigger.classList.toggle('is-active');
            mobNav.classList.toggle('js-is-open');
            projectWrapper.classList.toggle('js-is-open');
        }, false);
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

     ScrollOut({ 
        once: true,
    });

})